
@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-black-webfont.woff2') format('woff2'),
         url('satoshi-black-webfont.woff') format('woff');
    font-weight: 800;
    font-style: normal;

}




@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-blackitalic-webfont.woff2') format('woff2'),
         url('satoshi-blackitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-bold-webfont.woff2') format('woff2'),
         url('satoshi-bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-bolditalic-webfont.woff2') format('woff2'),
         url('satoshi-bolditalic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;

}




@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-italic-webfont.woff2') format('woff2'),
         url('satoshi-italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-light-webfont.woff2') format('woff2'),
         url('satoshi-light-webfont.woff') format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-lightitalic-webfont.woff2') format('woff2'),
         url('satoshi-lightitalic-webfont.woff') format('woff');
    font-weight: 300;
    font-style: italic;

}




@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-medium-webfont.woff2') format('woff2'),
         url('satoshi-medium-webfont.woff') format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'satoshimedium_italic';
    src: url('satoshi-mediumitalic-webfont.woff2') format('woff2'),
         url('satoshi-mediumitalic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Satoshi';
    src: url('satoshi-regular-webfont.woff2') format('woff2'),
         url('satoshi-regular-webfont.woff') format('woff');
    font-weight: 400;
    font-style: normal;

}




/* Georgia */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 31, 2022 */



@font-face {
    font-family: 'Georgia';
    src: url('georgia_italic-webfont.woff2') format('woff2'),
         url('georgia_italic-webfont.woff') format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Georgia';
    src: url('georgia-webfont.woff2') format('woff2'),
         url('georgia-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Georgia';
    src: url('georgia_bold_italic-webfont.woff2') format('woff2'),
         url('georgia_bold_italic-webfont.woff') format('woff');
    font-weight: 600;
    font-style: italic;

}




@font-face {
    font-family: 'Georgia';
    src: url('georgia_bold-webfont.woff2') format('woff2'),
         url('georgia_bold-webfont.woff') format('woff');
    font-weight: 600;
    font-style: normal;

}