:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low:
    0.1px 0.1px 0.2px hsl(var(--shadow-color) / 0.01),
    0.1px 0.2px 0.3px -0.2px hsl(var(--shadow-color) / 0.06),
    0.3px 0.4px 0.7px -0.4px hsl(var(--shadow-color) / 0.1);
  --shadow-elevation-medium:
    0.1px 0.1px 0.2px hsl(var(--shadow-color) / 0.01),
    0.3px 0.5px 0.8px -0.1px hsl(var(--shadow-color) / 0.05),
    0.7px 0.9px 1.7px -0.2px hsl(var(--shadow-color) / 0.08),
    1.4px 1.9px 3.4px -0.4px hsl(var(--shadow-color) / 0.12);
  --shadow-elevation-high:
    0.1px 0.1px 0.2px hsl(var(--shadow-color) / 0.01),
    0.6px 0.8px 1.4px hsl(var(--shadow-color) / 0.03),
    1.1px 1.5px 2.7px -0.1px hsl(var(--shadow-color) / 0.04),
    1.6px 2.2px 3.9px -0.1px hsl(var(--shadow-color) / 0.06),
    2.3px 3.1px 5.6px -0.2px hsl(var(--shadow-color) / 0.08),
    3.2px 4.3px 7.8px -0.3px hsl(var(--shadow-color) / 0.09),
    4.5px 6.1px 11px -0.3px hsl(var(--shadow-color) / 0.11),
    6.2px 8.4px 15.1px -0.4px hsl(var(--shadow-color) / 0.13);
}
