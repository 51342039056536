@import url(https://fonts.googleapis.com/css2?family=Inknut+Antiqua:wght@300&family=Raleway:wght@300;500;600&display=swap);
:root {
  --shadow-color: 0deg 0% 0%;
  --shadow-elevation-low:
    0.1px 0.1px 0.2px hsl(var(--shadow-color) / 0.01),
    0.1px 0.2px 0.3px -0.2px hsl(var(--shadow-color) / 0.06),
    0.3px 0.4px 0.7px -0.4px hsl(var(--shadow-color) / 0.1);
  --shadow-elevation-medium:
    0.1px 0.1px 0.2px hsl(var(--shadow-color) / 0.01),
    0.3px 0.5px 0.8px -0.1px hsl(var(--shadow-color) / 0.05),
    0.7px 0.9px 1.7px -0.2px hsl(var(--shadow-color) / 0.08),
    1.4px 1.9px 3.4px -0.4px hsl(var(--shadow-color) / 0.12);
  --shadow-elevation-high:
    0.1px 0.1px 0.2px hsl(var(--shadow-color) / 0.01),
    0.6px 0.8px 1.4px hsl(var(--shadow-color) / 0.03),
    1.1px 1.5px 2.7px -0.1px hsl(var(--shadow-color) / 0.04),
    1.6px 2.2px 3.9px -0.1px hsl(var(--shadow-color) / 0.06),
    2.3px 3.1px 5.6px -0.2px hsl(var(--shadow-color) / 0.08),
    3.2px 4.3px 7.8px -0.3px hsl(var(--shadow-color) / 0.09),
    4.5px 6.1px 11px -0.3px hsl(var(--shadow-color) / 0.11),
    6.2px 8.4px 15.1px -0.4px hsl(var(--shadow-color) / 0.13);
}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-black-webfont.66ceea13.woff2) format('woff2'),
         url(/static/media/satoshi-black-webfont.2bf7ccfb.woff) format('woff');
    font-weight: 800;
    font-style: normal;

}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-blackitalic-webfont.85b5d5f3.woff2) format('woff2'),
         url(/static/media/satoshi-blackitalic-webfont.f93dd2f9.woff) format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-bold-webfont.fe630be7.woff2) format('woff2'),
         url(/static/media/satoshi-bold-webfont.3d66dcfc.woff) format('woff');
    font-weight: 600;
    font-style: normal;

}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-bolditalic-webfont.7835f50b.woff2) format('woff2'),
         url(/static/media/satoshi-bolditalic-webfont.8d35b0eb.woff) format('woff');
    font-weight: 600;
    font-style: italic;

}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-italic-webfont.715cb569.woff2) format('woff2'),
         url(/static/media/satoshi-italic-webfont.a57c80a8.woff) format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-light-webfont.81b3ea2a.woff2) format('woff2'),
         url(/static/media/satoshi-light-webfont.80d51056.woff) format('woff');
    font-weight: 300;
    font-style: normal;

}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-lightitalic-webfont.6f7ea0f6.woff2) format('woff2'),
         url(/static/media/satoshi-lightitalic-webfont.34ce5b52.woff) format('woff');
    font-weight: 300;
    font-style: italic;

}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-medium-webfont.07d4da8a.woff2) format('woff2'),
         url(/static/media/satoshi-medium-webfont.2a778c56.woff) format('woff');
    font-weight: 500;
    font-style: normal;

}




@font-face {
    font-family: 'satoshimedium_italic';
    src: url(/static/media/satoshi-mediumitalic-webfont.929a9167.woff2) format('woff2'),
         url(/static/media/satoshi-mediumitalic-webfont.2aae129f.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Satoshi';
    src: url(/static/media/satoshi-regular-webfont.b7547a58.woff2) format('woff2'),
         url(/static/media/satoshi-regular-webfont.a6d55429.woff) format('woff');
    font-weight: 400;
    font-style: normal;

}




/* Georgia */

/*! Generated by Font Squirrel (https://www.fontsquirrel.com) on May 31, 2022 */



@font-face {
    font-family: 'Georgia';
    src: url(/static/media/georgia_italic-webfont.e5da62c6.woff2) format('woff2'),
         url(/static/media/georgia_italic-webfont.0898ecf1.woff) format('woff');
    font-weight: normal;
    font-style: italic;

}




@font-face {
    font-family: 'Georgia';
    src: url(/static/media/georgia-webfont.9dda2a44.woff2) format('woff2'),
         url(/static/media/georgia-webfont.aa72c5eb.woff) format('woff');
    font-weight: normal;
    font-style: normal;

}




@font-face {
    font-family: 'Georgia';
    src: url(/static/media/georgia_bold_italic-webfont.e3d62e12.woff2) format('woff2'),
         url(/static/media/georgia_bold_italic-webfont.48f1daa0.woff) format('woff');
    font-weight: 600;
    font-style: italic;

}




@font-face {
    font-family: 'Georgia';
    src: url(/static/media/georgia_bold-webfont.1bad4157.woff2) format('woff2'),
         url(/static/media/georgia_bold-webfont.b1139c3f.woff) format('woff');
    font-weight: 600;
    font-style: normal;

}
@media print {

  /* @page {
    size: a4 portrait;
    margin: 0cm !important;
  }

  html, body {
    width: 297mm;
    height: 205mm;
    border: solid 3px brown;
    background-color: cadetblue;
    margin: 0;
  } */

  body {
    margin-top: 0 !important;
  }

  #root {
    height: 100%;
  }

  .main-page {

    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    color: green important !;
  }

  .hide-print {
    display: none !important;
  }
}
