@media print {

  /* @page {
    size: a4 portrait;
    margin: 0cm !important;
  }

  html, body {
    width: 297mm;
    height: 205mm;
    border: solid 3px brown;
    background-color: cadetblue;
    margin: 0;
  } */

  body {
    margin-top: 0 !important;
  }

  #root {
    height: 100%;
  }

  .main-page {

    margin: 0;
    border: initial;
    border-radius: initial;
    width: initial;
    min-height: initial;
    box-shadow: initial;
    background: initial;
    page-break-after: always;
    color: green important !;
  }

  .hide-print {
    display: none !important;
  }
}